<template>
  <div class="root">
    <div class="content">
      <div class="header">
        <img class="headerDesc" src="@/assets/img/header.webp" alt="header" />
        <img class="headerMb1" src="@/assets/img/headerMb1.webp" alt="header" />
        <img class="headerMb2" src="@/assets/img/headerMb2.webp" alt="header" />
      </div>
      <div class="main">
        <div class="modal">
          <div class="modal-header">
            <h2 class="title">Регистрация</h2>
          </div>
          <div class="registration-form modal-right">
            <form
              action="#"
              id="registrationForm"
              :class="{'form_disabled': isRequestSending}"
              @submit.prevent="submitForm"
            >
              <div class="form-row">
                <label for="phone">Номер телефона</label>
                <div class="BlockPhone">
                  <select name="telCode" id="telCode" v-model="telCode">
                    <option value="+7" selected>+7</option>
                    <option value="+380">+380</option>
                  </select>
                  <input
                    style="width: 100%"
                    type="tel"
                    id="phone"
                    placeholder="Номер телефона"
                    required
                    v-model="phone"
                  />
                </div>
              </div>
              <div class="form-group" style="width: 100%">
                <label for="password">Пароль</label>
                <input
                  type="password"
                  id="password"
                  placeholder="Введите пароль"
                  required
                  v-model="password"
                />
              </div>
              <div class="form-group promo-code">
                <label for="promo">Промо код</label>
                <input type="text" id="promo" v-model="promocode" />
              </div>
              <div class="form-group">
                <label for="country">Страна</label>
                <select id="country" v-model="country">
                  <option value="RU" selected>Россия</option>
                  <option value="UA">Украина</option>
                </select>
              </div>
              <div class="form-group">
                <label for="currency">Валюта</label>
                <select id="currency" v-model="currency">
                  <option value="RUB" selected>RUB</option>
                  <option value="UAH">UAH</option>
                </select>
              </div>
              <div class="form-check">
                <label class="perm-check">
                  <input type="checkbox" id="check" checked />
                  <p>
                    Я безусловно согласен с
                    <a href="#">Правилами и условиямии</a> Мне больше 18 лет и
                    меньше 100 лет
                  </p>
                </label>
              </div>
              <button type="submit" class="submit-btn">
                Регистрация
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import axios from 'axios'

export default {
  name: 'landing-page',
  data () {
    return {
      telCode: '+7',
      phone: '',
      password: '',
      country: 'RU',
      currency: 'RUB',
      promocode: 'TOR',
      checkbox: false,
      isRequestSending: false
    }
  },
  methods: {
    submitForm () {
      if (this.isRequestSending) {
        return
      }

      this.isRequestSending = true

      const data = {
        user: {
          country: this.country,
          currency: this.currency,

          bonus_code: this.promocode,
          captcha: '',
          parent_refcode: '',
          profile_attributes: {
            age_acceptance: true,
            terms_acceptance: true,
            game_currency: this.currency,
            receive_newsletters: false,
            receive_promos: false,
            last_name: '',
            first_name: '',
            receive_sms_promos: false,
            nickname: '',
            currency: this.currency,
            language: 'ru',
            country: this.country
          },
          phone: {
            country: this.country,
            number: `${this.telCode}${this.phone}`
          },
          password: this.password,
          password_confirmation: this.password
        },
        lang: 'ru'
      }

      console.log(data)

      this.fetchData(data)
    },
    fetchData (data) {
      axios.post('/script.php', data, {
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
        .then((response) => {
          this.isRequestSending = false

          if (response.status === 200 || response.statusText === 'OK') {
            if (response.data) {
              const { token, url } = response.data

              if (token && token.length) {
                useToast().success('Успешная регистрация! Перенаправление...')
                document.location.href = url

                return
              }
            }

            useToast().error('Ошибка при регистрации')

            return
          }

          console.log('error', response)
          useToast().error('Ошибка при отправке запроса')
        })
        .catch((error) => {
          this.isRequestSending = false
          console.log('request error', error)
          useToast().error('Ошибка при отправке запроса')
        })
    }
  }
}
</script>

<style src="./style.scss" lang="scss"></style>
